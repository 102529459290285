body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* general fullcalendar style */
.fc-theme-standard .fc-scrollgrid {
  border-radius: 8px;
  background-color: white;
}

.fc-timegrid-slot {
  line-height: 50px;
}

/* remove dotted lines on the half hour */
.fc .fc-timegrid-slot-minor {
  border-top: none;
}

.fc-event.hidden {
  background-color: #80cbc4;
}

/* round corners + add gap between adjacent bg events for visibility */
.fc-timegrid-bg-harness {
  margin: 2px;
  border: 2px solid rgba(179,179,179,0.3);
  border-radius: 4px;
}

/* make rounded borders in selector above darken with event on hover */
.fc-timegrid-bg-harness:hover {
  border: 2px solid rgba(143,143,143,0.3);
}

.fc-timeline-bg-harness {
  border: 2px;
  border-radius: 4px;
}

.fc-toolbar-title{
  font-family: Raleway;
}

.fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: auto;
  padding: 0 .6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer; 
}

.fc .fc-button {
  border: none;
  color:rgb(125, 125, 125) ;
  background: rgba(143,143,143,0.2);
  background-image: none;
  font-family: Raleway;
  font-size: 0.875rem;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.fc .fc-button:hover {
  color: black ;
  background: rgba(143,143,143,0.2);
}

.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
  color: black;
  background: rgba(143,143,143,0.5);
}

.fc .fc-col-header-cell-cushion {
  font-family: Raleway;
}

/* make FC new reservation button imitate MUI button */
.fc .fc-newReservation-button {
  padding: 7.5px 16px;
  background: #FF5722;
  color: white ;
  box-shadow: 0 8px 16px 0 rgb(255 87 34 / 24%);
}

.fc .fc-newReservation-button:hover {
  padding: 7.5px 16px;
  background: #E64A19;
  color: white ;
  box-shadow: 0 8px 16px 0 rgb(255 87 34 / 24%);
}

.fc-timeline-event {
  border: 2px;
  border-radius: 4px;
}



/* Change MUI datatables header font */
.MuiTableCell-head {
  font-family: Raleway;
}
